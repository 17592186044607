'use client'

import Spline from '@splinetool/react-spline'

import { cn } from '../../utils'
import { useWebGLEnabled } from '../hooks/useWebGLEnabled'

interface SplineViewerProps {
  readonly scene: string
  readonly className?: string
}

export function SplineViewer({ className, scene }: SplineViewerProps) {
  const isWebGLEnabled = useWebGLEnabled()
  if (!isWebGLEnabled) return null
  return (
    <Spline
      scene={scene}
      className={cn('sm:hidden lg:block opacity-25 lg:opacity-100 w-full h-full', className)}
      style={{
        aspectRatio: '5 / 3',
        objectFit: 'contain',
        objectPosition: 'center right',
        pointerEvents: 'auto',
        position: 'absolute',
      }}
      onWheel={(e: React.WheelEvent) => {
        e.stopPropagation()
        window.scrollBy(0, e.deltaY)
      }}
    />
  )
}
