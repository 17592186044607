import { useEffect, useState } from 'react'

export const useWebGLEnabled = () => {
  const [isWebGLAvailable, setIsWebGLAvailable] = useState(true)

  useEffect(() => {
    // Check for WebGL support
    const canvas = document.createElement('canvas')
    const gl = canvas.getContext('webgl2') || canvas.getContext('webgl')
    setIsWebGLAvailable(!!gl)
  }, [])

  return isWebGLAvailable
}
